@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");

:root {
  /* Font size*/
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-md: 16px;
  --font-size-lg: 20px;
  --font-size-xl: 24px;

  /* Colors */
  --color-primary: #111827;
  --color-primaryLight: #0067b0;
  --color-secondary: #3b82f6;
  --color-secondaryLight: #eff6ff;
  --color-text: #1d1e22;
  --color-textSecondary: #6b7280;
  --color-textLight: #bababa;
  --color-textLightContrast: #f1f1f1;
  --color-textContrast: #ffffff;
  --color-border: #e2e1ec;
  --color-primaryHover: #0067b0;

  /* Color gray */
  --color-gray-50: #f9fafb;
  --color-gray-100: #f3f4f6;
  --color-gray-200: #e5e7eb;
  --color-gray-300: #d1d5db;
  --color-gray-400: #9ca3af;
  --color-gray-500: #6b7280;
  --color-gray-600: #4b5563;
  --color-gray-700: #374151;
  --color-gray-800: #1f2937;
  --color-gray-900: #111827;
  --color-text-field-gray: #f3f3f3;

  /* Color red */
  --color-red-100: #fee2e2;
  --color-red-400: #f87171;
  --color-red-rose: #f43f5e;
  --color-red-800: #991b1b;

  /* Color yellow */
  --color-yellow-100: #fef3c7;
  --color-yellow-400: #fbbf24;
  --color-yellow-800: #92400e;

  /* Color green */
  --color-green-100: #d1fae5;
  --color-green-400: #34d399;
  --color-green-800: #065f46;

  /* Color blue */
  --color-blue-50: #eff6ff;
  --color-blue-100: #dbeafe;
  --color-blue-400: #60a5fa;
  --color-blue-800: #1e40af;
  --color-blue-900: #332c48;

  /* Purple */
  --color-purple-50: #f5f3ff;
  --color-purple-100: #ede9fe;
  --color-purple-400: #a78bfa;
  --color-purple-500: #8b5cf6;
  --color-purple-800: #5b21b6;

  /* Colors used in export */
  --color-blue-export: #265783;
  --color-light-blue-export: rgba(84, 160, 255, 0.2);
  --color-export-status-failed: #ff3c3c;
  --color-export-status-success: #05a660;
  --color-export-status-warning: #f2994b;

  /* Box shadow */
  --box-shadow-xs: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --box-shadow-sm: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --box-shadow-md: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --box-shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --box-shadow-xl: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --box-shadow-xxl: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  --box-shadow-inner: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);

  /* Paddings */
  --padding-xs: 0.25rem;
  --padding-sm: 0.5rem;
  --padding-md: 0.75rem;
  --padding-lg: 1rem;
  --padding-xl: 1.25rem;
  --padding-xxl: 1.5rem;

  /* Components */
  --sidebar-width: 280px;
  --right-sidebar-width: 390px;
}

*,
::before,
::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  color: var(--color-text);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--icon,
.mapboxgl-ctrl-top-left .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--button {
  top: 5px;
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--button:hover {
  background: transparent;
}

.mapboxgl-ctrl-top-right {
  display: none;
}

.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  margin-left: 6px;
}

.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  margin-right: 6px;
}

.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip {
  margin-left: 6px;
}

.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  margin-right: 6px;
}

.mapboxgl-popup {
  max-width: none !important;
}

.mapboxgl-popup-content {
  font-family: "Inter";
  color: var(--color-text);
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3), 0 2px 6px 2px rgba(60, 64, 67, 0.15);
  padding: 0;
}

.mapboxgl-popup-tip {
  margin-top: -1px;
}

.mapboxgl-popup-close-button {
  margin-top: 0.1rem;
  margin-right: 0.5rem;
  font-size: 1.2rem;
  background-color: transparent;
}

.mapboxgl-popup-close-button:hover {
  background-color: transparent !important;
  font-weight: 500;
}

.Toastify__toast-body {
  white-space: pre-line;
}

.Toastify__toast-container--top-center {
  width: 450px;
}
